import FullDimmedLoadingManager from '@afterdoc-design-system/components/Atoms/Loading/FullDimmedLoading/FullDimmedLoadingManager';
import ToastManager from '@afterdoc-design-system/components/Atoms/Toast/ToastManager';
import AlertManager from '@afterdoc-design-system/components/Molecules/Alert/AlertManager';
import ContextMenuManager from '@afterdoc-design-system/components/Molecules/ContextMenu/ContextMenuManager';
import DialogManager from '@afterdoc-design-system/components/Molecules/Dialog/DialogManager';
import ModalManager from '@afterdoc-design-system/components/Molecules/Modal/ModalManager';
import OverlayPageManager from '@afterdoc-design-system/components/Molecules/OverlayPage/OverlayPageManager';
import ProgressBarManager from '@afterdoc-design-system/components/Molecules/ProgressBar/ProgressBarManager';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import calendar from 'dayjs/plugin/calendar';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isoWeek from 'dayjs/plugin/isoWeek';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { DevTools as JotaiDevTools } from 'jotai-devtools';
import 'jotai-devtools/styles.css';
import { Suspense } from 'react';
import 'react-image-gallery/styles/css/image-gallery.css';
import App from 'web/App';
import ErrorBoundary from 'web/shared/components/ErrorBoundary/ErrorBoundary';
import FullLoading from 'web/shared/components/FullLoading/FullLoading';
import NavigationBar from 'web/shared/components/NavigationBar/NavigationBar';
import PageLayout from 'web/shared/routes/PageLayout';
import '../../../packages/afterdoc-design-system/public/tailwind-inject.css';

dayjs.locale('ko');
dayjs.extend(relativeTime);
dayjs.extend(calendar);
dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(isoWeek);
dayjs.extend(weekOfYear);

// 10분 (기본 5분)
const DEFAULT_GC_TIME = 10 * 60 * 1000;
// 5분 (기본 0분)
const DEFAULT_STALE_TIME = 5 * 60 * 1000;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: DEFAULT_STALE_TIME,
      gcTime: DEFAULT_GC_TIME,
    },
  },
});

const AppContainer = () => {
  return (
    <QueryClientProvider client={queryClient}>
      {/* 개발 환경에서만 Jotai와 React-Query DevTools 틀어주기 */}
      {import.meta.env.VITE_IS_LOCAL_DEV === 'true' && (
        <>
          <JotaiDevTools isInitialOpen={false} position='top-left' />
          <ReactQueryDevtools initialIsOpen={false} buttonPosition='top-right' position='right' />
        </>
      )}
      <ErrorBoundary>
        <Suspense fallback={<FullLoading />}>
          <NavigationBar />
          <PageLayout />
          <App />
        </Suspense>
      </ErrorBoundary>
      <ToastManager />
      <DialogManager />
      <ProgressBarManager />
      <OverlayPageManager />
      <ModalManager />
      <AlertManager />
      <ContextMenuManager />
      <FullDimmedLoadingManager />
    </QueryClientProvider>
  );
};

export default AppContainer;
