import { alertService } from '@afterdoc-design-system/components/Molecules/Alert/Alert.service';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import {
  DataPushNotification,
  type DataPushNotificationDataMap,
} from 'web/apis/push/data-push/data-push-notification';
import type { DataPushNotificationQuery } from 'web/apis/push/data-push/data-push-type';
import {
  MessagePushNotification,
  type MessagePushNotificationDataMap,
} from 'web/apis/push/message-push/message-push-notification';
import type { QueryKeyParamsMap } from 'web/apis/swaggers/query-key';

export const usePushNotificationObserver = () => {
  const queryClient = useQueryClient();

  const handleInvalidateQueries = async <D extends keyof DataPushNotificationDataMap>(
    queries: DataPushNotificationQuery<keyof QueryKeyParamsMap, D>[],
    notificationData: DataPushNotificationDataMap[D],
  ) => {
    const firstQueries: DataPushNotificationQuery<keyof QueryKeyParamsMap, D>[] = [];
    const asyncQueries: DataPushNotificationQuery<keyof QueryKeyParamsMap, D>[] = [];

    for (const query of queries) {
      if (query.first) {
        firstQueries.push(query);
      } else {
        asyncQueries.push(query);
      }
    }

    const processQueryInvalidation = async (
      query: DataPushNotificationQuery<keyof QueryKeyParamsMap, D>,
    ) => {
      const specificQueryKey = query.getQueryKey
        ? query.getQueryKey(notificationData)
        : [query.queryKey];

      const hasSpecificQuery = queryClient.getQueryData(specificQueryKey) !== undefined;

      if (hasSpecificQuery) {
        console.info('Invalidating specific query:', specificQueryKey);
        await queryClient.invalidateQueries({ queryKey: specificQueryKey });
      } else {
        const broaderQueryKey = [query.queryKey];
        console.info(
          'Specific queryKey not found. Invalidating broader queryKey:',
          broaderQueryKey,
        );
        await queryClient.invalidateQueries({ queryKey: broaderQueryKey });
      }
    };

    for (const query of firstQueries) {
      await processQueryInvalidation(query);
    }

    await Promise.all(asyncQueries.map((query) => processQueryInvalidation(query)));
  };

  useEffect(() => {
    const handleNotification = (
      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
      _: any,
      payload: {
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        data: { code: string; [key: string]: any };
        notification: { title: string; body: string };
      },
    ) => {
      console.info('payload', payload);

      const notificationCode = payload.data.code;
      const notificationData = payload.data;

      const isDataPushNotification = notificationCode.startsWith('d_');
      const isMessagePushNotification = notificationCode.startsWith('m_');

      // 데이터 푸시 노티

      if (isDataPushNotification) {
        const dataNotificationCode = notificationCode as keyof DataPushNotificationDataMap;
        const dataNotificationData =
          notificationData as DataPushNotificationDataMap[typeof dataNotificationCode] & {
            code: typeof dataNotificationCode;
          };

        // Narrow down `queriesToRefetch` by type inference
        const queriesToRefetch = DataPushNotification[
          dataNotificationCode
        ] as DataPushNotificationQuery<keyof QueryKeyParamsMap, typeof dataNotificationCode>[];

        if (queriesToRefetch) {
          console.info('Received data push notification:', notificationCode);
          return handleInvalidateQueries<typeof dataNotificationCode>(
            queriesToRefetch,
            dataNotificationData,
          );
        }
      }
      // 메시지 푸시
      if (isMessagePushNotification) {
        const messageNotificationCode = notificationCode as keyof MessagePushNotificationDataMap;
        const messageNotificationData =
          notificationData as MessagePushNotificationDataMap[typeof messageNotificationCode] & {
            code: typeof messageNotificationCode;
          };

        const notificationTitle = payload.notification.title;
        const notificationBody = payload.notification.body;

        const handler = MessagePushNotification[messageNotificationCode];
        if (handler) {
          const alertProps = handler(messageNotificationData);

          alertService.push({
            title: notificationTitle,
            contents: <>{notificationBody}</>,
            ...alertProps,
          });
        } else {
          alertService.push({
            title: notificationTitle,
            contents: <>{notificationBody}</>,
          });
        }
      }
    };

    window.electron?.ipcRenderer.on('Application.receiveNotification', handleNotification);

    return () => {
      window.electron?.ipcRenderer.removeAllListeners('Application.receiveNotification');
    };
  }, [queryClient]);
};
