import { useMutation } from '@tanstack/react-query';
import type { UserUpdateFieldType } from '@templates/UserInfoSetting/containers/AccountSetting/types/user-update-field-type';
import { useState } from 'react';

interface UpdateServiceNoticeParams {
  _id: string;
}

interface CallbackAction {
  onSuccessCallback?: () => void;
  onFailureCallback?: () => void;
}

const updateServiceNoticeReadStatus = async ({ _id }: UpdateServiceNoticeParams) => {};

export const useServiceListReadState = () => {
  const [isUpdating, setIsUpdating] = useState(false);

  const updateMutation = useMutation({
    mutationFn: ({ _id }: UpdateServiceNoticeParams) => updateServiceNoticeReadStatus({ _id }),
    onMutate: () => setIsUpdating(true),
    onSettled: () => setIsUpdating(false),
  });

  const updateMutationAction = (id: string, callback: CallbackAction) => {
    setIsUpdating(true);
    callback.onSuccessCallback?.();
    setTimeout(() => {
      setIsUpdating(false);
    }, 200);
    // updateMutation.mutate(
    //   { _id: id },
    //   {
    //     onSuccess: () => onSuccessCallback?.(),
    //     onError: () => onFailureCallback?.(),
    //   },
    // );
  };

  return {
    isUpdating,
    updateMutationAction,
  };
};
