import ServiceNoticeDialogHeader from '@templates/ServiceNoticeDialog/compontents/ServiceNoticeDialogHeader';
import ServiceNoticeDialogTabs from '@templates/ServiceNoticeDialog/compontents/ServiceNoticeDialogTabs';
import ServiceNoticeList from '@templates/ServiceNoticeDialog/compontents/ServiceNoticeList/ServiceNoticeList';
import UnreadNotificationSection from '@templates/ServiceNoticeDialog/compontents/UnreadNotificationSection';
import type { ServiceNoticeTabs } from '@templates/ServiceNoticeDialog/types/service-notice-tabs';
import { useClickOutside } from 'hooks/use-click-outside';
import { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

interface ServiceNoticeDialogProps {
  onClose: () => void;
  handleNavigate: (href: string) => void;
}

export default function ServiceNoticeDialog({ onClose, handleNavigate }: ServiceNoticeDialogProps) {
  const [activeTab, setActiveTab] = useState<ServiceNoticeTabs['key']>('All');

  const divRef = useRef<HTMLDivElement>(null);

  useClickOutside({
    id: uuidv4(),
    ref: divRef,
    contentRef: divRef,
    onClose: () => onClose(),
  });

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') onClose();
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <>
      <div
        className='h-[80%] min-h-[200px] w-[360px] rounded-r16 bg-white50 shadow-inbox'
        ref={divRef}>
        <div className='flex h-full flex-col'>
          <ServiceNoticeDialogHeader onClose={onClose} />
          <ServiceNoticeDialogTabs activeTab={activeTab} setActiveTab={setActiveTab} />
          <UnreadNotificationSection />
          <ServiceNoticeList activeTab={activeTab} handleNavigate={handleNavigate} />
        </div>
      </div>
    </>
  );
}
