import ContainedButton from '@afterdoc-design-system/components/Atoms/Button/ContainedButton';
import type { AlertProps } from '@afterdoc-design-system/components/Molecules/Alert/Alert';

export const MESSAGE_PUSH_KEY = {
  m_ChatReceive_an: 'm_ChatReceive_an',
} as const;

export interface MessagePushNotificationDataMap {
  [MESSAGE_PUSH_KEY.m_ChatReceive_an]: {
    hospitalID: string;
  };
}

export const MessagePushNotification: {
  [K in keyof typeof MESSAGE_PUSH_KEY]: (
    data: K extends keyof MessagePushNotificationDataMap
      ? MessagePushNotificationDataMap[K]
      : undefined,
  ) => Partial<AlertProps>;
} = {
  [MESSAGE_PUSH_KEY.m_ChatReceive_an]: (data) => {
    console.info('push!');
    console.info('data', data.hospitalID);

    return {
      id: 'm_ChatReceive_an',
      navigatePath: '/customer-chat',
      bottomButtons: (
        <div className='flex w-full justify-end'>
          <ContainedButton
            btnColor='blue'
            buttonSize='small'
            onClick={() => {
              window.electron?.ipcRenderer.send('Application.showReservationWindow');
            }}>
            예약시트 이동
          </ContainedButton>
        </div>
      ),
    };
  },
};
