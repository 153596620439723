import Icon from '@afterdoc-design-system/components/Foundations/Icon/Icon';
import { dialogService } from '@afterdoc-design-system/components/Molecules/Dialog/Dialog.service';
import { modalService } from '@afterdoc-design-system/components/Molecules/Modal/Modal.service';
import { overlayPageService } from '@afterdoc-design-system/components/Molecules/OverlayPage/OverlayPage.service';
import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import { useAtomValue, useSetAtom } from 'jotai';
import { useLocation, useNavigate } from 'react-router-dom';
import { isThereSomeThingToSaveState } from 'web/shared/states/is-there-some-thing-to-save';
import { showModalBeforeRouteState } from 'web/shared/states/show-modal-before-route';
import { type MENU_ITEMS, ROUTES_PATH } from '../constants/menu-items';

interface MenuItemProps {
  item: (typeof MENU_ITEMS)[number];
  activatedKey: (typeof MENU_ITEMS)[number]['key'];
}

export default function MenuItem({ item, activatedKey }: MenuItemProps) {
  const navigate = useNavigate();
  const location = useLocation();

  // 페이지별로 저장하지 않은 정보가 있는지 확인
  const isThereSomeThingToSave = useAtomValue(isThereSomeThingToSaveState);
  // 페이지 이동 전에 경고 모달을 띄울지 확인
  const setShowModalBeforeRoute = useSetAtom(showModalBeforeRouteState);

  const openElectronPage = (nextPath: string): boolean => {
    switch (nextPath) {
      case ROUTES_PATH.ADMIN_CHAT:
        if (window?.electron === undefined) {
          alert('앱에서 실행해야 하는 기능입니다.');
          return true;
        }
        window?.electron?.ipcRenderer.send('Application.showManagerChatWindow');
        return true;
      case ROUTES_PATH.RESERVATION_MANAGEMENT:
        if (window?.electron === undefined) {
          alert('앱에서 실행해야 하는 기능입니다.');
          return true;
        }
        window?.electron?.ipcRenderer.send('Application.showReservationWindow');
        return true;
      default:
        return false;
    }
  };

  const deprecatedRoutingHandle = (currentPath: string, nextPath: string) => {
    const showModalIfUnsavedChanges = (key: string, nextPath: string) => {
      setShowModalBeforeRoute((prev) => ({
        ...prev,
        [key]: {
          showModal: true,
          nextPath,
        },
      }));
    };

    const isElectronPage = openElectronPage(nextPath);

    if (isElectronPage) {
      return;
    }

    switch (currentPath) {
      case ROUTES_PATH.CUSTOMER_MANAGEMENT:
        if (
          isThereSomeThingToSave.CUSTOMER_MANAGEMENT.customerInfoCustomerMemo ||
          isThereSomeThingToSave.CUSTOMER_MANAGEMENT.treatmentHistory
        ) {
          showModalIfUnsavedChanges('CUSTOMER_MANAGEMENT', item.key);
          return;
        }
        break;
      case ROUTES_PATH.HOSPITAL_SETTING:
        if (
          isThereSomeThingToSave.HOSPITAL_SETTING.hospitalSetting ||
          isThereSomeThingToSave.HOSPITAL_SETTING.serviceSetting ||
          isThereSomeThingToSave.HOSPITAL_SETTING.authorizationSetting
        ) {
          showModalIfUnsavedChanges('HOSPITAL_SETTING', item.key);
          return;
        }
        break;
      case ROUTES_PATH.CUSTOMER_CHAT:
        if (isThereSomeThingToSave.CUSTOMER_CHAT.customerChattingCustomerMemo) {
          showModalIfUnsavedChanges('CUSTOMER_CHAT', item.key);
          return;
        }
        break;
      case ROUTES_PATH.AUTOMATION:
        if (
          isThereSomeThingToSave.AUTOMATION.counselorAutomation ||
          isThereSomeThingToSave.AUTOMATION.marketingAutomation
        ) {
          showModalIfUnsavedChanges('AUTOMATION', item.key);
          return;
        }
        break;
      case ROUTES_PATH.CONTENT:
        if (
          isThereSomeThingToSave.CONTENT.eventManage ||
          isThereSomeThingToSave.CONTENT.homeCareManage ||
          isThereSomeThingToSave.CONTENT.noticeManage ||
          isThereSomeThingToSave.CONTENT.popupManage
        ) {
          showModalIfUnsavedChanges('CONTENT', item.key);
          return;
        }
        break;
      default:
        console.error('Unhandled case:', currentPath);
        break;
    }

    navigate(item.key);
    overlayPageService.popAll();
    modalService.popAll();
    dialogService.popAll();
  };

  const handleClick: React.MouseEventHandler<HTMLDivElement> = () => {
    const currentPath = location.pathname;
    const nextPath = item.key;

    if (
      currentPath === ROUTES_PATH.HOSPITAL_SETTING ||
      currentPath === ROUTES_PATH.CUSTOMER_CHAT ||
      currentPath === ROUTES_PATH.CONTENT ||
      currentPath === ROUTES_PATH.ADMIN_CHAT ||
      currentPath === ROUTES_PATH.RESERVATION_MANAGEMENT
    ) {
      // TODO: 추후 삭제
      deprecatedRoutingHandle(currentPath, nextPath);
    } else {
      const isElectronPage = openElectronPage(nextPath);
      if (!isElectronPage) {
        navigate(item.key);
      }
    }
  };

  return (
    <>
      <div
        onClick={handleClick}
        className='flex-center cursor-pointer flex-col gap-2 bg-white50 pt-10 pb-18'>
        <Icon
          name={activatedKey === item.key ? item.activeIcon : item.nonActiveIcon}
          color={activatedKey === item.key ? 'black700' : 'black100'}
          size={24}
        />
        <div
          className={customTwMerge(activatedKey === item.key ? 'text-Body10Bold' : 'text-Body10')}>
          {item.title}
        </div>
      </div>
      {item.hasLine && <div className='mx-10 border-b-2 border-b-white400' />}
    </>
  );
}
