import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';

export default function UnreadNotificationSection() {
  return (
    <div className='flex items-center border-white400 border-b bg-blueLight px-20 py-10'>
      <div className='flex flex-grow gap-4'>
        <span className='text-Body10 text-black200'>읽지 않은 알림</span>
        <span className='text-Body10Bold'>N개</span>
      </div>
      <div className='flex-center cursor-pointer gap-2 py-2 pr-4 pl-8'>
        <span className='text-Body11 text-blue500'>모두 읽음</span>
        <Icon name={'done'} color={'blue500'} size={16} />
      </div>
    </div>
  );
}
