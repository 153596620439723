import { apiClient } from '@apis/instances/api-client';
import { QUERY_KEY } from '@apis/swaggers/query-key';
import type {
  ServiceNoticesHandlerParams,
  ServiceNoticesHandlerResponse,
} from '@apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from '@shared/hooks/use-selected-hospital-info';
import { useUserInfo } from '@shared/hooks/use-user-info';
import { useQuery } from '@tanstack/react-query';
import type { ServiceNoticeTabs } from '@templates/ServiceNoticeDialog/types/service-notice-tabs';
import { useCallback, useEffect, useState } from 'react';
import { SHARED_UTILS } from 'utils/utils';

const defaultObj = {
  limit: 20,
  skip: 0,
  key: 'createdAt',
  way: -1,
};

const fetchServiceNoticeList = async (params: ServiceNoticesHandlerParams) => {
  const { data } = await apiClient.v3.serviceNoticesHandler(params);
  return SHARED_UTILS.api.checkApiResponse(data);
};

export const useServiceNoticeList = (activeTab: ServiceNoticeTabs['key']) => {
  const { userId: userID } = useUserInfo();
  const { hospitalID } = useSelectedHospitalInfo();

  const [isAllDataFetched, setIsAllDataFetched] = useState(false);

  const [serviceNoticeList, setServiceNoticeList] = useState<ServiceNoticesHandlerResponse['data']>(
    [],
  );

  const [queryParamKey, setQueryParamKey] = useState<
    ServiceNoticesHandlerParams & { category?: string }
  >({
    userID,
    hospitalID,
    ...defaultObj,
  });

  const { data, isLoading } = useQuery({
    queryKey: [QUERY_KEY.serviceNoticesHandler, queryParamKey] as const,
    queryFn: ({ queryKey }) => fetchServiceNoticeList(queryKey[1]),
    enabled: true,
    staleTime: 0,
    gcTime: 0,
  });

  useEffect(() => {
    setQueryParamKey((prev) => ({
      ...prev,
      skip: 0,
      category: activeTab === 'All' ? undefined : activeTab,
    }));
  }, [activeTab]);

  useEffect(() => {
    if (data !== undefined) {
      setServiceNoticeList((prev) => {
        if (!queryParamKey.skip) {
          return [...data];
        }
        return [...prev, ...data];
      });
      setIsAllDataFetched(data.length !== defaultObj.limit);
    }
  }, [data, queryParamKey]);

  const handleScrollToEnd = useCallback(() => {
    if (isLoading || isAllDataFetched) return;

    setQueryParamKey((prev) => ({
      ...prev,
      skip: serviceNoticeList.length + defaultObj.limit,
    }));
  }, [isLoading, isAllDataFetched]);

  return {
    serviceNoticeList,
    setServiceNoticeList,
    isLoading,
    isAllDataFetched,
    handleScrollToEnd,
  };
};
