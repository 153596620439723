import { dialogService } from '@afterdoc-design-system/components/Molecules/Dialog/Dialog.service';
import { modalService } from '@afterdoc-design-system/components/Molecules/Modal/Modal.service';
import { overlayPageService } from '@afterdoc-design-system/components/Molecules/OverlayPage/OverlayPage.service';
import HospitalProfileMenu from '@shared/components/NavigationBar/components/HospitalProfileMenu';
import ServiceNoticeMenu from '@shared/components/NavigationBar/components/ServiceNoticeMenu';
import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import { useAtomValue } from 'jotai';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { MENU_ITEMS, ROUTES_PATH } from 'web/shared/components/NavigationBar/constants/menu-items';
import { isServiceManagerNavigatorVisibleState } from 'web/shared/states/is-service-manager-navigator-visible';
import { isUnBlockedState } from 'web/shared/states/is-there-some-thing-to-save';
import MenuItem from './components/MenuItem';

export default function NavigationBar() {
  const location = useLocation();
  const prevLocation = useRef(location.pathname);

  const isUnBlocked = useAtomValue(isUnBlockedState);
  const isServiceManagerNavigatorVisible = useAtomValue(isServiceManagerNavigatorVisibleState);

  const [activatedKey, setActivatedKey] = useState<(typeof MENU_ITEMS)[number]['key']>(
    ROUTES_PATH.CUSTOMER_CHAT,
  );

  const [menuItems, setMenuItems] = useState(MENU_ITEMS);

  const memorizedMenuItems = useMemo(() => {
    return menuItems;
  }, [menuItems]);

  const hospitalSettingMenuItem = menuItems.find(
    (item) => item.key === ROUTES_PATH.HOSPITAL_SETTING,
  );

  const appVersion = import.meta.env.VITE_APP_VERSION;

  useEffect(() => {
    // 프로덕션 환경에서는 개발 중인 메뉴를 숨김
    if (process.env.NODE_ENV === 'production') {
      setMenuItems(MENU_ITEMS.filter((item) => !item.isDev));
    }
  }, []);

  useEffect(() => {
    if (location.pathname === '/') {
      setActivatedKey(ROUTES_PATH.CUSTOMER_CHAT);
    } else {
      setActivatedKey(location.pathname as (typeof MENU_ITEMS)[number]['key']);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (
      isUnBlocked &&
      prevLocation.current !== ROUTES_PATH.HOSPITAL_SETTING &&
      prevLocation.current !== ROUTES_PATH.CUSTOMER_CHAT &&
      prevLocation.current !== ROUTES_PATH.CONTENT
    ) {
      overlayPageService.popAll();
      modalService.popAll();
      dialogService.popAll();
    }

    prevLocation.current = location.pathname;
  }, [activatedKey]);

  return (
    <div
      className={`fixed top-0 left-0 z-50 flex h-full w-70 flex-col border-r border-r-white400 bg-white50 ${isServiceManagerNavigatorVisible ? 'ml-70' : 'ml-0'}`}>
      {/* 상단 메뉴 */}
      <div className='flex flex-col'>
        <ServiceNoticeMenu />
        <MenuItem
          key={memorizedMenuItems[0].key}
          item={memorizedMenuItems[0]}
          activatedKey={activatedKey}
        />
      </div>
      {/* 중간 메뉴 (스크롤 가능) */}
      <div
        className={customTwMerge(
          'scrollbar-hide flex flex-grow select-none flex-col justify-between overflow-y-auto',
        )}>
        <div className='flex flex-col'>
          {memorizedMenuItems.slice(1, -1).map((item) => (
            <MenuItem key={item.key} item={item} activatedKey={activatedKey} />
          ))}
        </div>
      </div>
      {/* 하단 메뉴 */}
      <div className='flex flex-col'>
        {appVersion && process.env.NODE_ENV === 'development' && (
          <div className='my-10 text-center text-Body12 text-gray400'>{appVersion}</div>
        )}
        {hospitalSettingMenuItem && (
          <MenuItem item={hospitalSettingMenuItem} activatedKey={activatedKey} />
        )}
        <HospitalProfileMenu />
      </div>
    </div>
  );
}
