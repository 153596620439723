import { isServiceManagerNavigatorVisibleState } from '@shared/states/is-service-manager-navigator-visible';
import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import ServiceNoticeDialog from '@templates/ServiceNoticeDialog/ServiceNoticeDialog';
import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import Portal from 'afterdoc-design-system/shared/Portal/Portal';
import { useAtomValue } from 'jotai/index';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function ServiceNoticeMenu() {
  const navigate = useNavigate();

  const isServiceManagerNavigatorVisible = useAtomValue(isServiceManagerNavigatorVisibleState);

  const [isActive, setActive] = useState(false);

  const handleNavigate = (href: string) => {
    setActive(false);
    const [basePath, queryString] = href.split('?');
    const searchParams = new URLSearchParams(queryString);
    searchParams.set('reload', `${Date.now()}`);

    const newHref = `${basePath}?${searchParams.toString()}`;
    navigate(newHref);
  };

  return (
    <div className='bg-white50 pt-10 pb-18'>
      <div
        className='flex-center cursor-pointer flex-col gap-2'
        onClick={() => setActive(!isActive)}>
        <Icon
          name={isActive ? 'bell' : 'bell-outline'}
          color={isActive ? 'black700' : 'black100'}
          size={24}
        />
        <div className={customTwMerge(isActive ? 'text-Body10Bold' : 'text-Body10')}>알림</div>
      </div>
      {isActive && (
        <Portal
          style={{
            position: 'absolute',
            left: isServiceManagerNavigatorVisible ? '150px' : '80px',
            zIndex: 9999,
            top: '10px',
            bottom: 0,
          }}>
          <ServiceNoticeDialog onClose={() => setActive(false)} handleNavigate={handleNavigate} />
        </Portal>
      )}
    </div>
  );
}
