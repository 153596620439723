import type { ServiceNoticesHandlerResponse } from '@apis/swaggers/swagger-docs';
import { formatCardCreatedDate } from '@templates/ServiceNoticeDialog/functions/format-card-created-date';
import Badge from 'afterdoc-design-system/components/Atoms/Badge/Badge';
import ContainedButton from 'afterdoc-design-system/components/Atoms/Button/ContainedButton';

interface ChatRoomJoinRequestCardProps {
  data: ServiceNoticesHandlerResponse['data'][number];
}

export default function ChatRoomJoinRequestCard({ data }: ChatRoomJoinRequestCardProps) {
  return (
    <>
      <div className='flex cursor-pointer flex-col gap-10 p-20'>
        <Badge isBadge={!data.isRead} wrapperClassName={'flex-grow'}>
          <span className='flex min-h-[24px] w-full items-center text-Header14 text-black700'>
            {data.title}
          </span>
        </Badge>
        <span className={'text-Body12 text-black500'}>{data.body}</span>
        <div className='ml-auto flex justify-center gap-10'>
          <ContainedButton btnColor={'secondary'} buttonSize={'small'}>
            채팅방으로 이동
          </ContainedButton>
          <ContainedButton buttonSize={'small'} disabled={data.isResponded}>
            승인
          </ContainedButton>
        </div>
        <span className='flex justify-end text-Body10 text-black200'>
          {formatCardCreatedDate(data.createdAt)}
        </span>
      </div>
    </>
  );
}
