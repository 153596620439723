import { createDataPushNotificationQuery } from 'web/apis/push/data-push/create-data-push-notification-query';
import type { DataPushNotificationQuery } from 'web/apis/push/data-push/data-push-type';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';

export const DATA_PUSH_KEY = {
  d_test_el: 'd_test_el',
  d_example_el: 'd_example_el',

  d_ServiceNotice_h: 'd_ServiceNotice_h',
  d_UserAuthorizationUpdate_el: 'd_UserAuthorizationUpdate_el',

  d_ChatReceive_h: 'd_ChatReceive_h',
  d_ChatReceive_an: 'd_ChatReceive_an',
  d_ChatReceive_el: 'd_ChatReceive_el',

  d_HServiceUpdate_el: 'd_HServiceUpdate_el',
  d_HServiceSuspend_el: 'd_HServiceSuspend_el',

  d_UserUpdate_el: 'd_UserUpdate_el',

  d_ChatBookmarkCreate_el: 'd_ChatBookmarkCreate_el',
  d_ChatBookmarkRemove_el: 'd_ChatBookmarkRemove_el',
  d_HospitalUpdate_el: 'd_HospitalUpdate_el',
  d_HServiceMemoUpdate_el: 'd_HServiceMemoUpdate_el',
} as const;

export interface DataPushNotificationDataMap {
  [DATA_PUSH_KEY.d_test_el]: {
    patientId: string;
    hospitalID: string;
  };
  [DATA_PUSH_KEY.d_example_el]: {};
  [DATA_PUSH_KEY.d_UserAuthorizationUpdate_el]: {};

  [DATA_PUSH_KEY.d_ChatReceive_h]: {};
  [DATA_PUSH_KEY.d_ChatReceive_el]: {};
  [DATA_PUSH_KEY.d_ChatReceive_an]: {};

  [DATA_PUSH_KEY.d_HServiceMemoUpdate_el]: {};
  [DATA_PUSH_KEY.d_HospitalUpdate_el]: {};

  [DATA_PUSH_KEY.d_HServiceUpdate_el]: {};
  [DATA_PUSH_KEY.d_HServiceSuspend_el]: {};

  [DATA_PUSH_KEY.d_UserUpdate_el]: {};

  [DATA_PUSH_KEY.d_ChatBookmarkCreate_el]: {};
  [DATA_PUSH_KEY.d_ChatBookmarkRemove_el]: {};
  [DATA_PUSH_KEY.d_ServiceNotice_h]: {};
}

export const DataPushNotification: {
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  [K in keyof typeof DATA_PUSH_KEY]: DataPushNotificationQuery<any, K>[];
} = {
  [DATA_PUSH_KEY.d_test_el]: [
    createDataPushNotificationQuery({
      queryKey: QUERY_KEY.apiPatientsElDetail,
      getQueryKey: (data) => [QUERY_KEY.apiPatientsElDetail, { patientId: data.patientId }],
    }),
    createDataPushNotificationQuery({
      queryKey: QUERY_KEY.apiAutomationsElDashboard,
      getQueryKey: (data) => [QUERY_KEY.apiAutomationsElDashboard, { hospitalID: data.hospitalID }],
    }),
    createDataPushNotificationQuery({
      queryKey: QUERY_KEY.apiTreatmentTagsEl,
      getQueryKey: (data) => [QUERY_KEY.apiTreatmentTagsEl, { hospitalID: data.hospitalID }],
    }),
  ],

  [DATA_PUSH_KEY.d_example_el]: [
    createDataPushNotificationQuery({
      queryKey: QUERY_KEY.apiPatientsElDetail,
      getQueryKey: () => [QUERY_KEY.apiPatientsElDetail],
    }),
    createDataPushNotificationQuery({
      queryKey: QUERY_KEY.apiAutomationsElDashboard,
      getQueryKey: () => [QUERY_KEY.apiAutomationsElDashboard],
    }),
    createDataPushNotificationQuery({
      queryKey: QUERY_KEY.apiTreatmentTagsEl,
      getQueryKey: () => [QUERY_KEY.apiTreatmentTagsEl],
    }),
  ],

  [DATA_PUSH_KEY.d_UserAuthorizationUpdate_el]: [
    createDataPushNotificationQuery({
      queryKey: QUERY_KEY.v2apiUserElFindOne,
      getQueryKey: () => [QUERY_KEY.v2apiUserElFindOne],
    }),
  ],

  //채팅 북마크 추가
  [DATA_PUSH_KEY.d_ChatBookmarkCreate_el]: [
    createDataPushNotificationQuery({
      queryKey: QUERY_KEY.apiChatElFind,
      getQueryKey: () => [QUERY_KEY.apiChatElFind],
    }),
  ],

  //채팅 북마크 삭제
  [DATA_PUSH_KEY.d_ChatBookmarkRemove_el]: [
    createDataPushNotificationQuery({
      queryKey: QUERY_KEY.apiChatElFind,
      getQueryKey: () => [QUERY_KEY.apiChatElFind],
    }),
  ],

  ///////////////////////////
  //채팅 메시지 수신 -> 채팅리스트 갱신
  [DATA_PUSH_KEY.d_ChatReceive_el]: [
    createDataPushNotificationQuery({
      queryKey: QUERY_KEY.apiChatroomElFind,
      getQueryKey: () => [QUERY_KEY.apiChatroomElFind],
    }),
    createDataPushNotificationQuery({
      queryKey: QUERY_KEY.apiChatroomElCount,
      getQueryKey: () => [QUERY_KEY.apiChatroomElCount],
    }),
  ],

  [DATA_PUSH_KEY.d_ChatReceive_h]: [
    createDataPushNotificationQuery({
      queryKey: QUERY_KEY.apiChatroomElFind,
      getQueryKey: () => [QUERY_KEY.apiChatroomElFind],
    }),
    createDataPushNotificationQuery({
      queryKey: QUERY_KEY.apiChatroomElCount,
      getQueryKey: () => [QUERY_KEY.apiChatroomElCount],
    }),
  ],

  [DATA_PUSH_KEY.d_ChatReceive_an]: [
    createDataPushNotificationQuery({
      queryKey: QUERY_KEY.apiChatroomElFind,
      getQueryKey: () => [QUERY_KEY.apiChatroomElFind],
    }),
    createDataPushNotificationQuery({
      queryKey: QUERY_KEY.apiChatroomElCount,
      getQueryKey: () => [QUERY_KEY.apiChatroomElCount],
    }),
  ],
  ///////////////////////////

  //d_ChatroomUpdate_el - 채팅방 정보가 변경되었을 때
  //(관리자 채팅)d_ChatroomUpdateDoctor_el

  //병원 정보가 변경되었을 때
  [DATA_PUSH_KEY.d_HospitalUpdate_el]: [
    createDataPushNotificationQuery({
      queryKey: QUERY_KEY.apiChatroomElFind,
      getQueryKey: () => [QUERY_KEY.apiChatroomElFind],
    }),
    createDataPushNotificationQuery({
      queryKey: QUERY_KEY.apiChatElFindId,
      getQueryKey: () => [QUERY_KEY.apiChatElFindId],
    }),
  ],

  //환자 메모 정보가 변경되었을 때
  [DATA_PUSH_KEY.d_HServiceMemoUpdate_el]: [
    createDataPushNotificationQuery({
      queryKey: QUERY_KEY.apiPatientsMemosElFindOne,
      getQueryKey: () => [QUERY_KEY.apiPatientsMemosElFindOne],
    }),
  ],
  //(다른 곳에서 처리해야할듯)d_Logout_el - 환자 서비스가 중단되었을 때

  //알람 발생시
  [DATA_PUSH_KEY.d_ServiceNotice_h]: [
    createDataPushNotificationQuery({
      queryKey: QUERY_KEY.serviceNoticesHandler,
      getQueryKey: () => [QUERY_KEY.serviceNoticesHandler],
    }),
  ],

  //고객 정보가 변경되었을 때
  [DATA_PUSH_KEY.d_HServiceUpdate_el]: [
    createDataPushNotificationQuery({
      queryKey: QUERY_KEY.apiChatroomElFind,
      getQueryKey: () => [QUERY_KEY.apiChatroomElFind],
    }),
    createDataPushNotificationQuery({
      queryKey: QUERY_KEY.apiChatroomElCount,
      getQueryKey: () => [QUERY_KEY.apiChatroomElCount],
    }),
  ],

  //고객을 이용중단 했을 때
  [DATA_PUSH_KEY.d_HServiceSuspend_el]: [
    createDataPushNotificationQuery({
      queryKey: QUERY_KEY.apiChatroomElFind,
      getQueryKey: () => [QUERY_KEY.apiChatroomElFind],
    }),
    createDataPushNotificationQuery({
      queryKey: QUERY_KEY.apiChatroomElCount,
      getQueryKey: () => [QUERY_KEY.apiChatroomElCount],
    }),
  ],

  //유저 정보가 변경되었을 때
  [DATA_PUSH_KEY.d_UserUpdate_el]: [
    createDataPushNotificationQuery({
      queryKey: QUERY_KEY.apiChatroomElFind,
      getQueryKey: () => [QUERY_KEY.apiChatroomElFind],
    }),
    createDataPushNotificationQuery({
      queryKey: QUERY_KEY.apiChatElFindId,
      getQueryKey: () => [QUERY_KEY.apiChatElFindId],
    }),
  ],

  // 다른 알림 코드를 추가하려면 아래와 같이 작성하세요.
  /*
  [DATA_PUSH_KEY.new_notification]: [
    {
      queryKey: QUERY_KEY.someOtherQuery1,
      getQueryKey: (data) => [
        QUERY_KEY.someOtherQuery1,
        { someParam: data.someParam1 },
      ],
    },
    {
      queryKey: QUERY_KEY.someOtherQuery2,
      getQueryKey: (data) => [
        QUERY_KEY.someOtherQuery2,
        { someParam: data.someParam2 },
      ],
    },
  ],
  */
};
