import type { ServiceNoticesHandlerResponse } from '@apis/swaggers/swagger-docs';
import ChatRoomJoinApprovedCard from '@templates/ServiceNoticeDialog/compontents/ServiceNoticeList/components/ChatRoomJoinApprovedCard';
import ChatRoomJoinRequestCard from '@templates/ServiceNoticeDialog/compontents/ServiceNoticeList/components/ChatRoomJoinRequestCard';
import ReservationCanceledCard from '@templates/ServiceNoticeDialog/compontents/ServiceNoticeList/components/ReservationCanceledCard';
import ReservationCreatedCard from '@templates/ServiceNoticeDialog/compontents/ServiceNoticeList/components/ReservationCreatedCard';
import ReservationUpdatedCard from '@templates/ServiceNoticeDialog/compontents/ServiceNoticeList/components/ReservationUpdatedCard';
import { useServiceListReadState } from '@templates/ServiceNoticeDialog/hooks/use-service-list-read-state';
import { useServiceNoticeList } from '@templates/ServiceNoticeDialog/hooks/use-service-notice-list';
import type { ServiceNoticeTabs } from '@templates/ServiceNoticeDialog/types/service-notice-tabs';
import Divider from 'afterdoc-design-system/components/Atoms/Divider/Divider';
import BaseLoading from 'afterdoc-design-system/components/Atoms/Loading/BaseLoading';
import Scrollbar from 'afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import { useEffect, useRef } from 'react';

interface ServiceNoticeListProps {
  activeTab: ServiceNoticeTabs['key'];
  handleNavigate: (href: string) => void;
}

export default function ServiceNoticeList({ activeTab, handleNavigate }: ServiceNoticeListProps) {
  const scrollbarRef = useRef<{
    scrollTo: (index: number, behavior?: ScrollBehavior) => void;
  }>(null);

  const { serviceNoticeList, setServiceNoticeList, isLoading, handleScrollToEnd } =
    useServiceNoticeList(activeTab);

  const { isUpdating, updateMutationAction } = useServiceListReadState();

  const handleMarkAsRead = (data: ServiceNoticesHandlerResponse['data'][number]) => {
    updateMutationAction(data._id, {
      onSuccessCallback: () => handleSuccessCallback(data),
    });
  };

  const handleSuccessCallback = (data: ServiceNoticesHandlerResponse['data'][number]) => {
    setServiceNoticeList((prev) =>
      prev.map((item) => (item._id === data._id ? { ...item, isRead: true } : item)),
    );

    switch (data.subCategory) {
      case 'PM_JoinChatRoom_Requestor': {
        const { chatRoomID } = data.data ?? {};
        const { userID: patientId } = data;
        if (chatRoomID) {
          handleNavigate(`/customer-chat?chatRoomId=${chatRoomID}&patientId=${patientId}`);
        }
        break;
      }

      case 'RM_CreateV2Reservation':
      case 'RM_UpdateV2Reservation':
      case 'RM_RemoveV2Reservation': {
        const reservationData = data.data;
        const payload =
          reservationData && 'reservationID' in reservationData && reservationData.reservationID
            ? { value: { _id: reservationData.reservationID } }
            : {};
        window?.electron?.ipcRenderer.send('Application.showReservationWindow', payload);
      }
    }
  };

  useEffect(() => {
    scrollbarRef.current?.scrollTo(0, 'auto');
  }, [activeTab]);

  return (
    <div className='relative flex-grow'>
      <Scrollbar disabledX onScrollToEnd={handleScrollToEnd} ref={scrollbarRef}>
        <div className='flex flex-col'>
          {(serviceNoticeList || []).map((item) => {
            let Component: React.ReactNode;
            switch (item.subCategory) {
              case 'PM_JoinChatRoom_Responder':
                Component = <ChatRoomJoinRequestCard data={item} />;
                break;
              case 'PM_JoinChatRoom_Requestor':
                Component = <ChatRoomJoinApprovedCard data={item} />;
                break;

              case 'RM_CreateV2Reservation':
                Component = <ReservationCreatedCard data={item} />;
                break;
              case 'RM_UpdateV2Reservation':
                Component = <ReservationUpdatedCard data={item} />;
                break;
              case 'RM_RemoveV2Reservation':
                Component = <ReservationCanceledCard data={item} />;
                break;

              default:
                Component = (
                  <span className='block h-[100px]'>
                    아직 작성중이거나 SaaS에서는 안보이는 메세지란다?
                  </span>
                );
                break;
            }

            return (
              <div
                key={item._id}
                onClick={() => {
                  if (!item.isRead && item.subCategory !== 'PM_JoinChatRoom_Responder')
                    handleMarkAsRead(item);
                }}>
                {Component}
                <Divider type={'line'} className={'bg-white400'} />
              </div>
            );
          })}
        </div>
      </Scrollbar>
      {(isLoading || isUpdating) && (
        <div className='absolute inset-0 flex-center bg-white50 opacity-40'>
          <BaseLoading />
        </div>
      )}
    </div>
  );
}
